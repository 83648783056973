
import { defineComponent, PropType, SetupContext } from 'vue';

/** Interfaces */
import { ClosedObligationI } from '@/interfaces/general.interface';

export default defineComponent({
  name: 'ClosedObligationsItem',
  emits: ['download-document'],
  props: {
    obligation: {
      type: Object as PropType<ClosedObligationI>,
      required: true,
    },
  },
  setup(props, { emit }: SetupContext) {
    const downloadDocument = () => {
      emit('download-document', props.obligation);
    };

    return {
      downloadDocument,
    };
  },
});
